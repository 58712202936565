import React from 'react';

import { FormControl, Text, Input, Button, Image } from '@chakra-ui/react';
import { useField } from 'formik';
import { FormikInputProps } from './FormikInput.props';
import ViewPasswordButton from 'assets/images/view_eye.png';
import HidePasswordButton from 'assets/images/hide_eye.png';
import isMultiBranches from 'utils/CheckWidgetType';

const passwordErrorFontSize = isMultiBranches() ? '11px' : 'sm';

const FormikInputView = ({
  name,
  label,
  helperText,
  labelProps,
  error,
  type,
  defaultValue,
  onPasswordToggle,
  ...props
}: FormikInputProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const hasError = error || meta.error;
  const topPosition = type == 'text' ? '10px' : '14px';
  const dynamicHeight = type == 'text' ? 6 : 4;
  const isPasswordField = type == 'text' || type == 'password';
  return (
    <FormControl>
      <Input
        type={type || name}
        id={name}
        aria-describedby={`${name}-helper-text`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(e.target.value)
        }
        borderTop="none"
        borderRight="none"
        borderLeft="none"
        borderRadius={0}
        color="inputTextColor"
        borderBottomColor="inputBorderColor"
        value={field.value || ''}
        bg="transparent"
        placeholder={label}
        fontSize="lg"
        padding="0"
        marginBottom={!hasError ? '15px' : '5px'}
        _focus={{
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottomColor: 'primaryColor',
          color: 'primaryColor',
        }}
        {...props}
      />
      {isPasswordField && (
        <Image
          onClick={onPasswordToggle}
          style={{ position: 'absolute', right: '5px', top: topPosition }}
          src={type == 'text' ? HidePasswordButton : ViewPasswordButton}
          width={isMultiBranches() ? '16px' : 6}
          height={isMultiBranches() ? '12px' : dynamicHeight}
        />
      )}
      {helperText && !hasError && (
        <Text color="infoColor" fontSize={passwordErrorFontSize}>
          {helperText}
        </Text>
      )}

      {hasError && (
        <Text color="errorColor" fontSize="sm">
          {(meta.touched && meta.error) || error}
        </Text>
      )}
    </FormControl>
  );
};

export default FormikInputView;
