enum ROUTES {
  AUTH_SIGNUP = 'AUTH_SIGNUP',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
}

export const routesSettings = Object.freeze({
  // AUTH ROUTE SETTINGS ENTRY
  [ROUTES.AUTH_SUCCESS]: {
    name: 'Success',
    path: '/success',
    getPath: () => `/success`,
  },

  [ROUTES.AUTH_SIGNUP]: {
    name: 'Signup',
    path: '/',
    getPath: () => `/`,
  },
});

export default ROUTES;
