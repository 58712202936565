import {
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import OopsImage from 'assets/images/oops.png';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const ErrorModal = (props: Props): JSX.Element => {
  return (
    <Modal
      isCentered
      onClose={props.onClose}
      isOpen={props.isOpen}
      motionPreset="slideInBottom">
      <ModalOverlay />

      <ModalContent width={252} height={187} padding={18} borderRadius={'20px'}>
        <Flex justifyContent="center">
          <Image width={100} src={OopsImage} />
        </Flex>
        <Flex justifyContent="center" paddingY="15px">
          <Text
            textAlign="center"
            width={170}
            fontSize={16}
            fontFamily="AvenirNextCondensed-MediumItalic"
            fontStyle="italic"
            fontWeight="normal">
            Something went wrong. Please try again later.
          </Text>
        </Flex>

        <div>
          <Flex
            justifyContent="center"
            align="center"
            width={'100%'}
            background="errorColor"
            _hover={{ background: 'rgba(250, 89, 154, 1)' }}
            height="36px"
            borderRadius={50}
            onClick={props.onClose}>
            <Text
              color="white"
              fontFamily="AvenirNextCondensed-BoldItalic"
              fontSize={16}>
              OK
            </Text>
          </Flex>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
