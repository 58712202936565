import { useRef, useState } from 'react';

import authApi, { SignupPayload } from 'api/auth';
import { useMutation } from 'react-query';
import { SignupProps } from './Signup.props';
import SignupView from './Signup.view';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}
const SignupContainer = ({ history }: SignupProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const queryParams = useQueryParams();
  const backgroundImage = queryParams.get('backgroundImage') || '';
  const referrer = queryParams.get('referrer');
  const formikRef = useRef<any>(null);
  const { mutate: onSignUp, isLoading } = useMutation(authApi.signup, {
    onSuccess: (response) => {
      if (response?.data) {
        formikRef.current.resetForm();
        history.push('/success');
      }
    },
    onError: (err: any) => {
      /* Show error if the email already exists and show modal if something went wrong*/
      err?.response?.status == 409 ? setEmailAlreadyExists(true) : onOpen();
    },
  });
  const onSignupPress = async (signupInfo: SignupPayload) => {
    const payload = {
      ...signupInfo,
      referrer,
    };
    onSignUp(payload);
  };

  return (
    <SignupView
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSignupPress}
      isLoading={isLoading}
      emailAlreadyExists={emailAlreadyExists}
      backgroundImage={backgroundImage}
      forwardedRef={formikRef}
    />
  );
};

export default SignupContainer;
