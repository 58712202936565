import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import Fonts from 'Fonts';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import theme from 'theme';
import Routes from './routes';

const queryClient = new QueryClient();

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <CSSReset />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );
}
