import React from 'react';
import { Image, Button, Heading, Link, Text } from '@chakra-ui/react';
import { SubmitButtonProps } from './SubmitButton.props';
import { useFormikContext } from 'formik';
import spinnerIcon from 'assets/images/spinner_icon.png';
import styles from './styles.module.css';

const CenterFormContainer = ({
  title,
  children = 'SUBMIT',
  ...rest
}: SubmitButtonProps): JSX.Element => {
  const { isSubmitting, isValid } = useFormikContext();

  return (
    <>
      <Button type="submit" mt={10} isFullWidth letterSpacing={0} {...rest}>
        <Text fontFamily="AvenirNextCondensed-BoldItalic">{children}</Text>
      </Button>
    </>
  );
};

export default CenterFormContainer;
