import { useMemo, useState } from 'react';
import { Flex, Link, Text, Image, Center, Select, Box } from '@chakra-ui/react';
import FormikInput from 'components/primitives/FormikInput';
import { Field, Form, Formik } from 'formik';

import { SignupViewProps } from './Signup.props';
import CenterFormContainer from 'components/core/ConterFormContainer';
import BackgroundImage from 'assets/images/main-background.jpg';
import BannerImage from 'assets/images/banner_1.png';
import logo from 'assets/images/AffiliateWidget-05.png';
import SubmitButton from 'components/primitives/SubmitButton';
import { passwordErrorMessage, SignupFormValidator } from './Signup.utils';
import spinnerIcon from 'assets/images/loader.png';
import styles from './styles.module.css';
import ErrorModal from './ErrorModal';
import { ValidationError } from 'yup';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import isMultiBranches from 'utils/CheckWidgetType';

const RESPONSIVE_INPUT_MARGIN = [7, '10px'];
const MAX_FORM_WIDTH = '508px';
const RESPONSIVE_FORM_HEIGHT = ['600px', '600px'];

const stores = [
  {
    option: 'Phil Hoffman Travel',
    value: 'PHT000001',
  },
  {
    option: 'Barossa Valley',
    value: 'PHT000002',
  },
  {
    option: 'Corporate Travel',
    value: 'PHT000003',
  },
  {
    option: 'Gawler',
    value: 'PHT000004',
  },
  {
    option: 'Glenelg',
    value: 'PHT000005',
  },
  {
    option: 'Hyde Park',
    value: 'PHT000006',
  },
  {
    option: 'Modbury',
    value: 'PHT000007',
  },
  {
    option: 'Norwood',
    value: 'PHT000008',
  },
  {
    option: 'Semaphore',
    value: 'PHT000009',
  },
  {
    option: 'Stirling',
    value: 'PHT000010',
  },

  {
    option: 'Victor Harbor',
    value: 'PHT000011',
  },
];

const SignupFormContainer = ({ children }: { children: any }): JSX.Element => {
  return (
    <Flex
      background={'rgba(255, 255, 255, 0.05)'}
      backdropFilter="blur(10px)"
      maxW={MAX_FORM_WIDTH}
      width={['90%', 'initial']}
      height={RESPONSIVE_FORM_HEIGHT}
      borderRadius="14px"
      boxShadow="3px 3px 6px #0000000F"
      flexDir="column"
      align="center">
      {children}
    </Flex>
  );
};
const EmailExistMessage = () => (
  <Text textAlign="center" color="errorColor" fontSize="xs">
    We’ve found an account linked to your email address. Email us at{' '}
    <Link
      color="errorColor"
      textDecoration="underline"
      href="mailto:help@pelikinmoney.com?Subject=Hello">
      help@pelikinmoney.com
    </Link>{' '}
    if this doesn’t seem right.
  </Text>
);

const InfoMessage = () => (
  <Text
    color="infoColor"
    fontSize={isMultiBranches() ? '11px' : '14px'}
    opacity="70%">
    Pelikin Card only available for those 18 and over.
  </Text>
);

const ButtonInfo = ({
  emailAlreadyExists,
}: {
  emailAlreadyExists: boolean;
}) => {
  if (emailAlreadyExists) {
    return <EmailExistMessage />;
  }

  return <InfoMessage />;
};

const SignupView = (props: SignupViewProps): JSX.Element => {
  const { search } = useLocation();
  const { type } = queryString.parse(search);

  const pelikinLogoWidth = type ? '190px' : '100%';
  const containerWidth: string = '600px';
  const submitBtnHeight = type ? '32px' : 'var(--chakra-sizes-10)';
  const submitBtnFontSize = type ? '12px' : '16px';

  const backgroundImage = useMemo(
    () =>
      props.backgroundImage ? `url(${props.backgroundImage})` : BackgroundImage,
    [props.backgroundImage],
  );

  const onPasswordToggle = () => {
    setPasswordToggle(!isViewPassWord);
  };
  const [isViewPassWord, setPasswordToggle] = useState(false);
  const inputPasswordType = isViewPassWord ? 'text' : 'password';

  return (
    <CenterFormContainer>
      <SignupFormContainer>
        <Flex
          zIndex={1}
          flexDir="column"
          margin={['-100px', '0']}
          background={type ? '#141B54 0% 0% no-repeat padding-box' : 'none'}
          padding={['10px', '30px']}
          width={['420px', '680px']}
          height={['100vh', '100%']}
          borderRadius={'11px'}
          alignItems={['center']}
          justifyContent={'start'}>
          <Center mb={['10px', '20px']}>
            <Image
              width={[pelikinLogoWidth]}
              maxWidth={[219, 319]}
              maxHeight={[142, 161.5]}
              src={BannerImage}
              alt="Pelikin banner"
            />
          </Center>
          <Formik
            initialValues={{
              firstname: '',
              lastname: '',
              email: '',
              password: '',
              branch: '',
            }}
            validationSchema={SignupFormValidator}
            onSubmit={async (values) => {
              await props.onSubmit(values);
            }}
            innerRef={props.forwardedRef}>
            {({ errors, values, handleChange }) => (
              <Form>
                {type && type === 'm' && (
                  <Flex
                    flexDirection={['column', 'row']}
                    justifyContent={'center'}>
                    <Flex flexDirection={'column'} alignItems={'center'}>
                      <Box>
                        <Image
                          width={['300px', '162px']}
                          src={logo}
                          alt="pht-logo"
                          alignSelf={'center'}
                          marginY={4}
                        />
                        <div
                          style={{
                            margin: '6px',
                            color: 'transparent',
                            fontSize: 'var(--chakra-fontSizes-sm)',
                          }}>
                          {!errors.branch ? '' : ' - '}
                        </div>
                      </Box>
                    </Flex>

                    <Flex flexDirection={'column'}>
                      <Box>
                        <Select
                          marginY={4}
                          background="#141B54 0% 0% no-repeat padding-box;"
                          borderRadius={'8px'}
                          fontSize={'.9rem'}
                          placeholder="SELECT YOUR BRANCH"
                          id="branch"
                          name="branch"
                          value={values.branch}
                          onChange={handleChange}
                          color="#87D1F1"
                          fontFamily="AvenirNextCondensed-BoldItalic"
                          border="none"
                          focusBorderColor="none"
                          opacity="1"
                          height={'43px'}
                          alignSelf={'start'}>
                          {stores.map((store) => (
                            <option value={store.value} key={store.value}>
                              {store.option}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      {errors.branch && (
                        <div
                          style={{
                            margin: '6px',
                            color: 'var(--chakra-colors-errorColor)',
                            fontSize: 'var(--chakra-fontSizes-sm)',
                          }}>
                          {!errors.branch ? '' : errors.branch}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                )}

                <Flex
                  marginTop={7}
                  justifyContent={['flex-start', 'space-between']}
                  flexDirection={['column', 'row']}>
                  <Flex marginBottom={RESPONSIVE_INPUT_MARGIN}>
                    <FormikInput
                      type="firstname"
                      name="firstname"
                      label="First Name"
                      paddingRight={[0, '25px']}
                      fontFamily={'AvenirNext-Medium'}
                      fontSize={'.9rem'}
                    />
                  </Flex>

                  <Flex
                    marginBottom={RESPONSIVE_INPUT_MARGIN}
                    marginLeft={['initial', 7]}>
                    <FormikInput
                      type="lastname"
                      name="lastname"
                      label="Last Name"
                      paddingRight={[0, '25px']}
                      fontFamily={'AvenirNext-Medium'}
                      fontSize={'.9rem'}
                    />
                  </Flex>
                </Flex>
                <Flex flexDirection="column">
                  <Flex marginBottom={RESPONSIVE_INPUT_MARGIN}>
                    <FormikInput
                      name="email"
                      label="Email Address"
                      fontFamily={'AvenirNext-Medium'}
                      fontSize={'.9rem'}
                    />
                  </Flex>

                  <Flex marginBottom={RESPONSIVE_INPUT_MARGIN}>
                    <FormikInput
                      type={inputPasswordType}
                      name="password"
                      label="Create Password"
                      helperText={passwordErrorMessage}
                      onPasswordToggle={onPasswordToggle}
                      fontFamily={'AvenirNext-Medium'}
                      fontSize={'.9rem'}
                    />
                  </Flex>
                </Flex>
                <Center flexDirection="column" flex={1}>
                  <SubmitButton
                    mb="10px"
                    height={submitBtnHeight}
                    fontSize={submitBtnFontSize}>
                    {props.isLoading ? (
                      <Image
                        className={styles.rotate}
                        src={spinnerIcon}
                        width={6}
                        height={6}
                      />
                    ) : (
                      'JOIN PELIKIN'
                    )}
                  </SubmitButton>
                  <ButtonInfo emailAlreadyExists={props.emailAlreadyExists} />
                </Center>
              </Form>
            )}
          </Formik>
          <ErrorModal onClose={props.onClose} isOpen={props.isOpen} />
        </Flex>
      </SignupFormContainer>
    </CenterFormContainer>
  );
};

export default SignupView;
