import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
import { Switch } from 'react-router-dom';
// IMPORT ROUTE
import Signup from 'routes/auth/Signup';
import SuccessScreen from './SuccessScreen';
import ROUTES, { routesSettings } from 'constants/routes';

const routeConfig = Object.freeze({
  [ROUTES.AUTH_SUCCESS]: {
    component: SuccessScreen,
    path: routesSettings.AUTH_SUCCESS.path,
  },

  [ROUTES.AUTH_SIGNUP]: {
    component: Signup,
    path: routesSettings.AUTH_SIGNUP.path,
  },
});

const AuthRoutes = (): JSX.Element => {
  return (
    <>
      <Switch>
        {Object.entries(routeConfig).map(([key, val]) => (
          <RouteWithSubRoutes key={key} {...val} />
        ))}
      </Switch>
    </>
  );
};

export default AuthRoutes;
