import { queryAllByAltText } from '@testing-library/react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import AuthRoutes from './auth';

const RootRoutes = (): JSX.Element => {
  const Routes = useMemo(() => {
    return AuthRoutes;
  }, []);

  return <Routes />;
};

export default RootRoutes;
