import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'AvenirNextLTPro-Medium',
    body: 'AvenirNextLTPro-Medium',
    mono: 'AvenirNextLTPro-Medium',
  },
  colors: {
    brand: {
      500: '#6728BB',
    },

    primaryColor: 'rgba(204,255,3)',
    secondaryColor: '#1A1F71',
    infoColor: '#8ED8F8',

    bgColor: '#E2E2E2',

    buttonBgColor: 'rgba(204,255,3)',
    buttonTextColor: '#1A1F71',
    buttonBgColorHover: 'rgba(204,255,3,0.5)',

    solidButtonBgHoverColor: 'rgba(204,255,3,0.5)',
    solidButtonBgClickColor: 'rgba(204,255,3,0.5)',

    outlineButtonBgHoverColor: 'rgba(204,255,3)',
    outlineButtonBgClickColor: 'rgba(204,255,3)',

    complementaryColor: '#7AB462',
    textColor: 'rgb(255,255,255)',

    inputColor: '#FAFAFA',
    inputTextColor: 'rgb(255,255,255)',
    inputBorderColor: '#55638E',

    successColor: '#34D17D',
    errorColor: '#FA2E81',
  },
  fontSizes: {
    xs: '13px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '22px',
    '2xl': '26px',
    '3xl': '28px',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 700,
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 700,
        fontFamily: 'Open Sans',
        textTransform: 'uppercase',
        fontSize: 14,
        color: '#C7C7C7',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        backgroundColor: 'buttonBgColor',
        borderRadius: '50px',
        letterSpacing: 'widest',
      },
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      variants: {
        'with-shadow': {
          bg: 'red.400',
        },
        solid: () => ({
          bg: 'buttonBgColor',
          color: 'buttonTextColor',
          px: '20px',
          _hover: {
            backgroundColor: 'solidButtonBgHoverColor',
          },
          _active: {
            backgroundColor: 'solidButtonBgClickColor',
          },
        }),
        outline: () => ({
          px: '20px',
          borderColor: 'buttonBgColor',
          backgroundColor: 'buttonTextColor',
          color: 'buttonBgColor',
          _hover: {
            backgroundColor: 'outlineButtonBgHoverColor',
          },
          _active: {
            backgroundColor: 'outlineButtonBgClickColor',
          },
        }),
        ghost: () => ({
          px: '20px',
          color: 'buttonBgColor',
          bg: 'transparent',
          _hover: {
            color: 'solidButtonBgHoverColor',
          },
          _active: {
            color: 'solidButtonBgClickColor',
          },
        }),
      },
    },
    Link: {
      baseStyle: {
        color: 'buttonBgColor',
      },
    },
  },
});

export default theme;
