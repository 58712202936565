import { Box, Container, Flex, Image, Text, Link } from '@chakra-ui/react';

import { SuccessScreenViewProps } from './SuccessScreen.props';
import styles from './styles.module.css';
import headerLogoSucess from 'assets/images/header_text.png';
import mobileImage from 'assets/images/phone_image.png';
import qrCode from 'assets/images/QR_Code.png';
import googlePlay from 'assets/images/google_Play.png';
import appleStore from 'assets/images/apple_store.png';
import useWindowDimensions from 'hooks/useWindowDimensions';
import closeIcon from 'assets/images/close_icon.png';

const SuccessScreenView = ({
  history,
}: SuccessScreenViewProps): JSX.Element => {
  const { height, width } = useWindowDimensions();
  const imageMobileWidth = height > 810 ? 300 : 240;
  const isMobileDevice = width < 768;
  return (
    <Flex className={styles.container}>
      <Container
        className={styles.box}
        height={['87.5vh', '600px']}
        width={['90%', '508px']}
        position={['absolute', 'relative']}>
        <div className={styles.headerContainer}>
          <Image
            src={closeIcon}
            className={styles.closeButton}
            maxHeight={90}
            width={4}
            height={4}
            position={'absolute'}
            right={5}
            top={5}
            onClick={() => history.goBack()}
          />
          <Image
            src={headerLogoSucess}
            className={styles.headerText}
            maxHeight={90}
          />
          <div className={styles.descriptionContainer}>
            <Text className={styles.description}>
              GET THE APP TO SETUP YOUR TRAVEL CARD!
            </Text>
          </div>
          <Box
            className={styles.leftContainer}
            position={['unset', 'absolute']}
            display={['flex', 'initial']}>
            <Image
              src={qrCode}
              display={['none', 'flex']}
              className={styles.qrCode}
            />
            <Link
              href="https://apps.apple.com/au/app/pelikin/id1444828880"
              isExternal
              marginRight={['8px', 'unset']}
              className={styles.appleStore}>
              <Image src={appleStore} className={styles.applicationImage} />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=co.pelikin.pelikin"
              isExternal
              marginLeft={['8px', 'unset']}
              className={styles.googlePlay}>
              <Image src={googlePlay} className={styles.applicationImage} />
            </Link>
          </Box>
          {isMobileDevice && (
            <Box
              className={styles.mobileImage}
              left={['unset', '70px']}
              width={imageMobileWidth}
              backgroundImage={mobileImage}
              backgroundSize={'cover'}
              marginTop={[15, 'unset']}
            />
          )}
        </div>
        {!isMobileDevice && (
          <div className={styles.desktopMobileImage}>
            <Image src={mobileImage} />
          </div>
        )}

        <Box
          className={styles.bgGradientBottom}
          bgGradient="linear(#5F216300, #141757)"
        />
      </Container>
    </Flex>
  );
};

export default SuccessScreenView;
