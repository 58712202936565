import api from 'api/instance';
import { AxiosResponse } from 'axios';

const URL = `${process.env.REACT_APP_API_URL}`;

export type SignupPayload = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  referrer?: any;
  branch?: string;
};

export interface SignupResponse extends AxiosResponse {}
const signup = async (payload: SignupPayload): Promise<SignupResponse> => {
  const newPayload = {
    first_name: payload.firstname,
    last_name: payload.lastname,
    email: payload.email,
    password: payload.password,
    referrer: !payload.branch ? payload.referrer : payload.branch,
  };

  console.log({ payload: payload });
  console.log(newPayload);

  const { data } = await api.post<SignupResponse>(
    `${URL}/onboarding/affiliate`,
    newPayload,
  );
  return data;
};

export default {
  signup,
};
