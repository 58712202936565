import React from 'react';

import { SuccessScreenProps } from './SuccessScreen.props';
import SuccessScreenView from './SuccessScreen.view';

const SuccessScreenContainer = ({
  history,
}: SuccessScreenProps): JSX.Element => {
  return <SuccessScreenView history={history} />;
};

export default SuccessScreenContainer;
