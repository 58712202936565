import passwordRegex from 'utils/passwordRegex';
import * as Yup from 'yup';
import isMultiBranches from 'utils/CheckWidgetType';

export const passwordErrorMessage =
  'Include at least 8 characters and 1 number or symbol.';

let condition = isMultiBranches();

export const SignupFormValidator = Yup.object().shape({
  branch:
    condition === true
      ? Yup.string().required('Please select your branch')
      : Yup.string(),
  //branch: Yup.string().required('Please select your branch'),
  firstname: Yup.string().required('Enter First Name'),
  lastname: Yup.string().required('Enter Last Name'),
  email: Yup.string().email().trim().required('Enter a Valid Email Address'),
  password: Yup.string()
    .required('Please enter your password')
    .matches(passwordRegex, passwordErrorMessage),
});
