import { Box, Flex, Heading } from '@chakra-ui/react';

import { ConterFormContainerProps } from './ConterFormContainer.props';
import useWindowDimensions from 'hooks/useWindowDimensions';

const CenterFormContainer = ({
  title,
  children,
  ...rest
}: ConterFormContainerProps): JSX.Element => {
  const { height } = useWindowDimensions();
  return (
    <Flex
      justify="center"
      height="100vh"
      overflow="hidden"
      width="100%"
      flex={1}
      mx="auto"
      bg="#262D63"
      align="center"
      {...rest}>
      <Flex width="100%" align="center" flexDir="column">
        <Box>
          {title && (
            <Heading
              fontFamily="Monument Extended"
              textAlign="center"
              size="md"
              mt={[5, 10]}
              mb={[10, 20]}>
              {title}
            </Heading>
          )}
        </Box>
        {children}
      </Flex>
    </Flex>
  );
};

export default CenterFormContainer;
