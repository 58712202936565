import { Global } from '@emotion/react';
const Fonts = () => (
  <Global
    styles={`
        @font-face {
            font-family: 'AvenirNextLTPro-Medium';
            font-display: swap;
            src: url("./fonts/AvenirNextLTPro-Medium.otf");
            font-weight: normal;
        }
        @font-face {
          font-family: 'AvenirNextCondensed-BoldItalic';
          font-display: swap;
          src: url("./fonts/AvenirNextCondensed-BoldItalic.otf");
          font-weight: normal;
        }
      `}
  />
);
export default Fonts;
